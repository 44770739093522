<template>
  <div class="viewer-container py-3">
    <alert v-if="!intake || !report" class="mb-0">Nothing to view...</alert>
    <template v-if="!hideMainReport">
      <div ref="report">
        <div v-if="report && !intake.reportHtml">
          <div class="section-box border rounded mb-5" v-if="clientInfo.name">
            <h2 class="title border-bottom py-4 rounded-top px-2">
              Client Information
            </h2>
            <div class="content px-3 pb-2">
              <div class="item d-flex">
                <div class="key">ID:</div>
                <div class="value">{{ clientInfo["patient_id"] }}</div>
              </div>
              <div class="item d-flex">
                <div class="key">Name:</div>
                <div class="value">{{ clientInfo["name"] }}</div>
              </div>
              <div class="item d-flex">
                <div class="key">Gender:</div>
                <div class="value">{{ clientInfo["sex"] }}</div>
              </div>
              <div class="item d-flex">
                <div class="key">Date Of Birth:</div>
                <div class="value">{{ clientInfo["dob"] }}</div>
              </div>
              <div class="item d-flex">
                <div class="key">Age:</div>
                <div class="value">{{ clientInfo["age"] }}</div>
              </div>
              <div class="item d-flex" v-if="clientInfo['diagnosis']">
                <div class="key">Diagnosis:</div>
                <div class="value">{{ clientInfo["diagnosis"] }}</div>
              </div>
              <div class="item d-flex" v-if="clientInfo['assessment']">
                <div class="key">Assessment:</div>
                <div class="value">{{ clientInfo["assessment"] }}</div>
              </div>
              <div class="item d-flex" v-if="clientInfo['treatment']">
                <div class="key">Treatment:</div>
                <div class="value">{{ clientInfo["treatment"] }}</div>
              </div>
              <div class="item d-flex" v-if="clientInfo['intervention']">
                <div class="key">Intervention:</div>
                <div class="value">{{ clientInfo["intervention"] }}</div>
              </div>
            </div>
          </div>
          <tree-node :node="report"></tree-node>
        </div>
        <div v-if="report && intake.reportHtml">
          <div class="content px-3 pb-2" v-html="report"></div>
        </div>
      </div>
    </template>
    <div class="report-summary" v-if="reportSummary && !narrativeEditing">
      <div class="text-right copy-container">
        <button
          class="btn btn-sm btn-link p-0 text-black copy-btn"
          @click="copyText('narrative')"
        >
          Copy <i class="fa-regular fa-copy"></i>
        </button>
        <span class="px-2" v-if="user && !user.isPatient">|</span>
        <button
          class="btn btn-sm btn-link p-0 text-black copy-btn"
          @click="editSummary('narrative')"
          v-if="user && !user.isPatient"
        >
          Edit <i class="fa-regular fa-edit ml-1"></i>
        </button>
      </div>
      <div v-html="reportSummary" ref="summary"></div>
    </div>
    <div class="report-summary pt-4" v-if="reportSummary && narrativeEditing">
      <ckeditor :editor="editor" v-model="textToEdit" :config="editorConfig">
      </ckeditor>
      <div class="d-flex justify-content-center pt-4">
        <button
          class="btn btn-black"
          @click="!reportModifying && modifyReport()"
        >
          <span
            v-if="reportModifying"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          Save as Engagement Note
        </button>
        <button
          class="btn btn-danger ml-5"
          @click="!reportModifying && editSummary()"
        >
          Cancel
        </button>
      </div>
    </div>
    <div class="report-summary soap-summary" v-if="soapSummary && !soapEditing">
      <div class="text-right">
        <button
          class="btn btn-sm btn-link p-0 text-black copy-btn"
          @click="copyText('soap')"
        >
          Copy <i class="fa-regular fa-copy"></i>
        </button>
        <span class="px-2" v-if="user && !user.isPatient">|</span>
        <button
          class="btn btn-sm btn-link p-0 text-black copy-btn"
          v-if="user && !user.isPatient"
          @click="editSummary('soap')"
        >
          Edit <i class="fa-regular fa-edit ml-1"></i>
        </button>
      </div>
      <div v-html="soapSummary" ref="soap"></div>
    </div>

    <div class="report-summary pt-4" v-if="soapSummary && soapEditing">
      <ckeditor :editor="editor" v-model="textToEdit" :config="editorConfig">
      </ckeditor>
      <div class="d-flex justify-content-center pt-4">
        <button
          class="btn btn-black"
          @click="!reportModifying && modifyReport()"
        >
          <span
            v-if="reportModifying"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          Save as Engagement Note
        </button>
        <button
          class="btn btn-danger ml-5"
          @click="!reportModifying && editSummary()"
        >
          Cancel
        </button>
      </div>
    </div>

    <div
      class="report-summary pt-4"
      v-if="
        customEditing ||
        (user &&
          !user.isPatient &&
          intake.type &&
          !reportModifying &&
          !intake.edited_report &&
          !customEditing &&
          !soapEditing &&
          !narrativeEditing)
      "
    >
      <div v-if="customEditing">
        <ckeditor :editor="editor" v-model="textToEdit" :config="editorConfig">
        </ckeditor>
        <div class="d-flex justify-content-center pt-4">
          <button
            class="btn btn-black"
            @click="!reportModifying && modifyReport('custom')"
          >
            <span
              v-if="reportModifying"
              class="fa fa-spin fa-circle-notch mr-2"
            ></span>
            Save Engagement Note
          </button>
          <button
            class="btn btn-danger ml-5"
            @click="!reportModifying && editSummary('')"
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        class="d-flex justify-content-center"
        v-if="
          user &&
          !user.isPatient &&
          !reportModifying &&
          !intake.edited_report &&
          !customEditing &&
          !soapEditing &&
          !narrativeEditing
        "
      >
        <button class="btn btn-black" @click="editSummary('custom')">
          Add Engagement Note
        </button>
      </div>
    </div>

    <div
      class="report-summary custom-summary"
      v-if="intake.edited_report && !customEditing"
    >
      <div class="text-right">
        <button
          class="btn btn-sm btn-link p-0 text-black copy-btn"
          @click="copyText('custom')"
        >
          Copy <i class="fa-regular fa-copy"></i>
        </button>
        <span class="px-2" v-if="user && !user.isPatient">|</span>
        <button
          class="btn btn-sm btn-link p-0 text-black copy-btn"
          @click="editSummary('custom')"
          v-if="user && !user.isPatient"
        >
          Edit <i class="fa-regular fa-edit ml-1"></i>
        </button>
        <span class="px-2" v-if="user && !user.isPatient">|</span>
        <button
          class="btn btn-sm btn-link p-0 text-black copy-btn"
          v-if="user && !user.isPatient"
          @click="deleteCustomReport()"
        >
          Delete <i class="fa fa-trash ml-1"></i>
        </button>
      </div>
      <div class="d-flex justify-content-between border-bottom pt-3 pb-2 mb-3">
        <p class="h2">Engagement Note</p>
        <p v-if="user && !user.isPatient">
          Last updated by
          <span class="font-weight-bold">{{ intake.edited_by }}</span>
          <span class="small">
            {{ intake.edited_at ? getDate(intake.edited_at) : "Now" }}</span
          >
        </p>
      </div>
      <div v-html="intake.edited_report" ref="customReport"></div>
    </div>

    <div
      class="text-center pt-4 d-flex flex-wrap justify-content-center"
      v-if="report"
    >
      <template v-if="!intake.reportHtml">
        <button
          class="btn btn-secondary mr-md-4 mb-2 mb-md-0 col-10 col-md-auto"
          v-if="!reportSummary"
          @click="
            generateSummary(
              ['4499', '4500', '4501'].includes(`${intake.type}`)
                ? 'testingResults'
                : 'narrative'
            )
          "
          :disabled="narrativeLoading"
        >
          <span
            v-if="narrativeLoading"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          {{
            ["4499", "4500", "4501"].includes(`${intake.type}`)
              ? "Summarize Test Results"
              : "Generate Narrative Summary"
          }}
        </button>
        <button
          class="btn btn-secondary mr-md-4 mb-2 mb-md-0 col-10 col-md-auto"
          v-if="reportSummary"
          @click="copyText('narrative')"
        >
          <span
            v-if="narrativeLoading"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          Copy
          {{
            ["4499", "4500", "4501"].includes(`${intake.type}`)
              ? "Testing Results"
              : "Narrative Summary"
          }}
          to Clipboard
        </button>
        <button
          class="btn btn-secondary mr-md-4 mb-2 mb-md-0 col-10 col-md-auto"
          v-if="!soapSummary"
          @click="generateSummary('soap')"
          :disabled="soapLoading"
        >
          <span
            v-if="soapLoading"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          Generate SOAP Note Format
        </button>
        <button
          class="btn btn-secondary mr-md-4 mb-2 mb-md-0 col-10 col-md-auto"
          v-if="soapSummary"
          @click="copyText('soap')"
        >
          <span
            v-if="soapLoading"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          Copy SOAP Note to Clipboard
        </button>
      </template>
      <button
        v-if="!hideMainReport"
        class="btn btn-black col-10 col-md-auto"
        @click="copyText('report')"
      >
        Copy Report to Clipboard
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import TreeNode from "./TreeNode.vue";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import { richTextConfig } from "../../../tasks/RichTextConfig";

export default {
  name: "IntakeViewer",
  components: {
    TreeNode,
  },
  props: ["intake", "hideMainReport"],
  data() {
    return {
      reportSummary: null,
      soapSummary: null,
      narrativeLoading: false,
      soapLoading: false,
      soapEditing: false,
      narrativeEditing: false,
      customEditing: false,
      reportModifying: false,
      textToEdit: "",
      editor: richTextConfig.classicEditor,
      editorConfig: {
        placeholder: richTextConfig.placeholder,
        plugins: richTextConfig.plugins,
        ...richTextConfig.basicToolbar,
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    report: function () {
      if (this.intake?.reportHtml) {
        return this.intake.reportHtml;
      }
      const originReport =
        JSON.parse(JSON.stringify(this.intake?.report)) || {};
      const keysToRemove = [
        "name",
        "Name",
        "Patient ID",
        "PatientID",
        "patient_id",
        "Sex",
        "sex",
        "DOB",
        "dob",
        "Age",
        "age",
        "Diagnosis",
        "diagnosis",
        "Assessment",
        "assessment",
        "Treatment",
        "treatment",
        "Intervention",
        "intervention",
      ];

      keysToRemove.forEach((key) => {
        delete originReport[key];
      });
      return originReport;
    },
    clientInfo: function () {
      const report = this.intake?.report || {};
      if (report.name || report.Name) {
        return {
          name: report.name || report.Name,
          patient_id:
            report["Patient ID"] || report["PatientID"] || report["patient_id"],
          sex: report["Sex"] || report["sex"],
          dob: report["DOB"] || report["dob"],
          age: report["Age"] || report["age"],
          diagnosis: report["Diagnosis"] || report["diagnosis"],
          assessment: report["Assessment"] || report["assessment"],
          treatment: report["Treatment"] || report["treatment"],
          intervention: report["Intervention"] || report["intervention"],
        };
      }
      return {};
    },
  },
  methods: {
    ...mapActions({
      generateReportSummary: "intakeForms/generateSummary",
      editReportSummary: "intakeForms/editReport",
    }),
    getDate(formDate) {
      return dayjs(formDate)
        .add(dayjs(new Date(formDate)).utcOffset(), "minute")
        .format("YYYY-MM-DD (hh:mm A)");
    },
    copyText(type) {
      let textToCopy = "";
      let title = "";
      if (type == "narrative") {
        textToCopy = this.$refs.summary.innerText;
        title = "The report summary has been copied to your clipboard.";
      } else if (type == "soap") {
        textToCopy = this.$refs.soap.innerText;
        title = "The report summary has been copied to your clipboard.";
      } else if (type == "custom") {
        textToCopy = this.$refs.customReport.innerText;
        title = "The engagement note has been copied to your clipboard.";
      } else {
        textToCopy = this.$refs.report.innerText;
        title = "The report has been copied to your clipboard.";
      }
      navigator.clipboard.writeText(textToCopy).then(
        function () {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "success",
            title,
          });
        },
        function (err) {
          console.error("Async: Could not copy report: ", err);
        }
      );
    },
    editSummary(type = "") {
      this.narrativeEditing = false;
      this.soapEditing = false;
      this.customEditing = false;
      this.textToEdit = "";
      if (type == "narrative") {
        this.textToEdit = this.reportSummary;
        this.narrativeEditing = true;
      } else if (type == "soap") {
        this.textToEdit = this.soapSummary;
        this.soapEditing = true;
      } else if (type == "custom") {
        this.customEditing = true;
        this.textToEdit = this.intake.edited_report || "";
      }
    },
    deleteCustomReport() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "This action is irreversible",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.reportModifying = true;
            this.editReportSummary({
              id: this.intake.id,
              edited_report: null,
            }).then((data) => {
              this.reportModifying = false;
              if (data) {
                this.soapEditing = false;
                this.narrativeEditing = false;
                this.customEditing = false;
                this.$emit("change", data);
              }
            });
          }
        });
    },
    modifyReport() {
      if (this.intake.edited_report) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        return swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "Saving the changes will overwrite the engagement note",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, save!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.reportModifying = true;
              this.editReportSummary({
                id: this.intake.id,
                edited_report: this.textToEdit,
              }).then((data) => {
                this.reportModifying = false;
                if (data) {
                  this.soapEditing = false;
                  this.narrativeEditing = false;
                  this.customEditing = false;
                  this.$emit("change", data);
                }
              });
            }
          });
      } else {
        this.reportModifying = true;
        this.editReportSummary({
          id: this.intake.id,
          edited_report: this.textToEdit,
        }).then((data) => {
          this.reportModifying = false;
          if (data) {
            this.soapEditing = false;
            this.narrativeEditing = false;
            this.customEditing = false;
            this.$emit("change", data);
          }
        });
      }
    },
    generateSummary(type) {
      if (type == "narrative" || type == "testingResults") {
        this.narrativeLoading = true;
      } else if (type == "soap") {
        this.soapLoading = true;
      }
      this.generateReportSummary({ id: this.intake.id, type }).then(
        (summary) => {
          const result = (summary || "")
            .replace("```html", "")
            .replace("```", "");
          if (type == "narrative" || type == "testingResults") {
            this.reportSummary = result;
            this.narrativeLoading = false;
          } else if (type == "soap") {
            this.soapSummary = result;
            this.soapLoading = false;
          }
        }
      );
    },
  },
};
</script>
<style lang="scss">
.viewer-container {
  .section-box {
    .title {
      background-color: rgba(37, 37, 37, 0.4);
      color: #ffffff;
    }
    .content {
      .item {
        margin-bottom: 4px;
      }
      .key {
        font-weight: bold;
        margin-right: 12px;
        text-transform: capitalize;
      }
    }
  }
  .report-summary {
    border-top: 6px solid #c8c8c861;
    border-radius: 8px;
    margin-top: 12px;
    padding: 4px 12px 12px 12px;
    background-color: #c8c8c861;

    &.custom-summary {
      border-top-color: var(--secondary-color);
    }
    h2,
    h3 {
      text-decoration: underline;
      margin-top: 12px;
    }
    p {
      margin-bottom: 0;
    }
  }
  .copy-btn {
    &:hover {
      color: #1657af !important;
    }
  }
  .ck-editor__editable_inline {
    max-height: 50vh;
  }

  .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
  .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
